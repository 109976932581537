import type { FunctionComponent } from 'react';
import { Container } from '../../components/layout/grid';
import type { NineBlockGridFragment } from '../../graphql/fragments/NineBlockGridFragment.graphql';
import { Block } from '../block';
import styles from './nine-block-grid.module.scss';

/**
 * Section that displays a grid of 9 blocks, 3 x 3.
 */

export const NineBlockGrid: FunctionComponent<Props> = ({
  blocks,
  anchorId
}) => {
  return <Container className={styles.gridContainer} id={anchorId} data-sentry-element="Container" data-sentry-component="NineBlockGrid" data-sentry-source-file="nine-block-grid.tsx">
            <div className={styles.grid}>
                {blocks?.items.map((item, index) => <Block key={index} titleMd={item.titleMd} subtitleMd={item.subtitleMd} image={item.image} backgroundColor={item.backgroundColor} isCenterBlock={item.isCenterBlock} subtextTopMd={item.subtextTopMd} path={item.path} />)}
            </div>
        </Container>;
};
export default NineBlockGrid;
type Props = Omit<NineBlockGridFragment, 'sys' | 'type'>;